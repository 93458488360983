<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500"> <v-card>
        <v-card-title class="text-h5">
          Install PMT App
        </v-card-title>

        <v-card-text>
          To install the app on your device, please click on Install App button
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="text-right">
          <v-spacer />
          <v-btn color="secondary" text @click="dialog = false">
            close </v-btn>
          <v-btn ref="addBtn" color="primary" text @click="clickCallback">
            Install App
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<!--
<template>
  <div class="text-left bg-light fix-width" v-if="deferredPrompt">
    <v-container class="my-0">
      <v-row no-gutters>
        <v-col cols="3">
          <v-img class="logo-img" src="img/icons/icon-96x96.png"></v-img>
        </v-col>
        <v-col cols="9">
          <h1 class="heading-text">Project Management Tool</h1>
          <p>To install the app please click on following button</p>
          <v-btn ref="addBtn" class="add-button" @click="clickCallback">
            Install App
          </v-btn>
        </v-col>
        <v-col cols="12" class="text-center"> </v-col>
      </v-row>
    </v-container>
  </div>
</template>-->

<script>
/* v-if="deferredPrompt"*/
export default {
  name: "AddToHomeScreen",
  data: () => ({
    deferredPrompt: false,
    dialog: false
  }),
  mounted() {
    this.captureEvent();
  },
  methods: {
    captureEvent() {
      window.addEventListener("beforeinstallprompt", (e) => {
        // ! Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.

        this.dialog = true;
        this.deferredPrompt = e;

      });
    },
    clickCallback() {
      console.log("Log:" + this.deferredPrompt);

      // Show the prompt
      this.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          // Call another function?
        }
        this.dialog = false;
        this.deferredPrompt = false;
      });
    },
  },
};
</script>
<style scoped>
.fix-width {
  /*max-width: 500px;
  margin: 0px auto;
  border-radius: 10px;
  margin-bottom: 5px;
  position: absolute;
  left: 100px;
  top: 100px;*/

  position: fixed;
  inset: 0px;
  width: 100px;
  height: 300px;
  max-width: 100vw;
  max-height: 100dvh;
  margin: auto;

}

.bg-light {
  background-color: lightgray;
}

.heading-text {
  font-size: 1rem;
}

.logo-img {
  width: 80px;
}
</style>