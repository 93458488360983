<template>
  <v-dialog max-width="600px" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn
        fab
        depressed
        small
        :class="`${iconColor}--text`"
        color="transparent"
        v-on="on"
      >
        <v-icon medium>mdi-plus-thick</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <h2>{{ title }}</h2>
        <v-btn
          small
          text
          fab
          color="grey darken-1"
          @click="dialog = false"
          class="close-btn"
        >
          <v-icon medium>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form class="px-1" ref="form" enctype="multipart/form-data">
          <v-text-field
            label="Title"
            v-model="name"
            prepend-icon="mdi-format-title"
            :rules="inputRules"
          ></v-text-field>
          <v-textarea
            v-model="desc"
            prepend-icon="mdi-text"
            rows="2"
            label="Description"
          ></v-textarea>
          <v-text-field
            label="Upload Instruction Files (zip)"
            @click="pickFile"
            v-model="fileName"
            prepend-icon="mdi-attachment"
          ></v-text-field>
          <input
            type="file"
            style="display: none"
            ref="insFiles"
            accept=".zip"
            @change="onFilePicked"
          />
          <v-menu
            lazy
            :close-on-content-click="true"
            full-width
            :nudge-right="40"
            max-width="290px"
            min-width="290px"
            transition="v-scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                label="Deadline Date"
                v-model="deadlineDate"
                prepend-icon="mdi-calendar"
                :rules="inputRules"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title
              v-model="deadlineDate"
              :date-format="(date) => new Date(date).toDateString()"
              :formatted-value.sync="deadlineDate"
            ></v-date-picker>
          </v-menu>
          <v-spacer></v-spacer>
          <v-btn
            text
            :disabled="loading"
            class="primary mx-0 mt-3"
            @click="submit()"
            :loading="loading"
            >{{ btnLabel }}</v-btn
          >
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import EventBus from "@/eventBus";
import axios from "axios";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  props: ["iconColor"],
  data() {
    return {
      name: "",
      desc: "",
      deadlineDate: "",
      userInfo: null,
      title: "Add New Project",
      btnLabel: "Add Project",
      fileName: "",
      id: 0,
      inputRules: [
        (v) => (v && v.length >= 3) || "Minimum length is 3 characters",
      ],
      loading: false,
      dialog: false,
      action: "get",
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        const vm = this;
        this.loading = true;
        console.log("Client Id " + vm.userInfo.id);
        var formData = new FormData();
        formData.append("title", vm.name);
        formData.append("deadline", vm.deadlineDate);
        formData.append("desc", vm.desc);
        formData.append("clientId", vm.userInfo.id);
        if (vm.$refs.insFiles.files.length > 0) {
          formData.append("insFile", vm.$refs.insFiles.files[0]);
        }
        if (vm.action == "update") {
          console.log("Language Id:" + vm.id);
          formData.append("id", vm.id);
        }
        axios
          .post(BASE_URL + "client/project.php?action=" + vm.action, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function(response) {
            vm.loading = false;
            console.log(response);
            vm.message = response.data.message;
            EventBus.$emit("display-message", vm.message);
            if (!response.data.error) {
              if (vm.action == "update") {
                EventBus.$emit("project-update", vm.error);
              } else {
                EventBus.$emit("project-added", vm.error);
              }
            }
            vm.dialog = false;
            // vm.name = "";
            vm.$refs.form.reset();
          })
          .catch(function(error) {
            vm.loading = false;
            console.log(error);
            EventBus.$emit("display-message", "Some error occured!");
          });
      }
    },
    uploadFiles() {},
    showDialog() {
      this.btnLabel = "Add Project";
      this.name = "";
      //this.dialog = true;
      this.action = "add";
      this.title = "Add New Project";
      this.$refs.form.reset();
    },
    pickFile() {
      this.$refs.insFiles.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      console.log(files[0].name);
      if (files[0] !== undefined) {
        this.fileName = files[0].name;
        if (this.fileName.lastIndexOf(".") <= 0) {
          return;
        }
      }
    },
  },
  computed: {},
  mounted() {
    this.action = "add";
    EventBus.$on("edit-project", (data) => {
      this.name = data.name;
      this.namePun = data.nameNative;
      this.id = data.id;
      this.btnLabel = "Update Project";
      this.dialog = true;
      this.action = "update";
      this.title = "Update Project";
    });
  },
  created() {
    this.userInfo = this.$store.state.userlogged;
  },
};
</script>
<style scoped>
.close-btn {
  position: absolute;
  right: 10px;
}
</style>
